import "styles/pages/contact.scss"

import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import $ from "jquery"

import Layout from "components/layout"
import Seo from "components/seo"
import PageHeader from "components/PageHeader/PageHeader"
import Breadcrumbs from "components/Breadcrumbs/Breadcrumbs"

const Contact = ({ data }) => {
  const title = "Samband"
  const [query, setQuery] = useState("")
  const [filterDepartment, setFilterDepartment] = useState("none")

  useEffect(() => {
    $(".searchInput").keyup(function () {
      const soludeildLenght = $("#soludeild .col-count").length
      const framleioslaLenght = $("#framleiosla .col-count").length
      const handilinLenght = $("#handilin .col-count").length
      const verkstaourLenght = $("#verkstaour .col-count").length
      const fyrisitingLenght = $("#fyrisiting .col-count").length
      const utbunadurLenght = $("#utbunadur .col-count").length
      const nevndLenght = $("#nevnd .col-count").length

      if (soludeildLenght === 0) {
        $("#soludeild").hide()
      } else {
        $("#soludeild").show()
      }
      if (framleioslaLenght === 0) {
        $("#framleiosla").hide()
      } else {
        $("#framleiosla").show()
      }
      if (handilinLenght === 0) {
        $("#handilin").hide()
      } else {
        $("#handilin").show()
      }
      if (verkstaourLenght === 0) {
        $("#verkstaour").hide()
      } else {
        $("#verkstaour").show()
      }
      if (fyrisitingLenght === 0) {
        $("#fyrisiting").hide()
      } else {
        $("#fyrisiting").show()
      }
      if (utbunadurLenght === 0) {
        $("#utbunadur").hide()
      } else {
        $("#utbunadur").show()
      }
      if (nevndLenght === 0) {
        $("#nevnd").hide()
      } else {
        $("#nevnd").show()
      }
    })
  })

  return (
    <Layout>
      <Seo title={title} />
      <PageHeader title={title} />
      <Breadcrumbs title={title} />

      <section className="contact-info">
        <div className="container-fluid">
          <div className="row justify-content-center">
            {data.contactFields.acfContact.contactContentColumns.map(
              (item, index) => (
                <div className="col-md-3 col-6" key={index}>
                  <address>
                    <strong>{item.contactContentHeader}</strong>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.contactContentDescription,
                      }}
                    />
                  </address>
                </div>
              )
            )}
          </div>
        </div>
      </section>

      <section className="contact-filters">
        <div className="container-fluid">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-6">
              <div className="input-wrapper">
                <input
                  type="text"
                  name="search"
                  placeholder="NAVN"
                  value={query}
                  onChange={({ target: { value } }) => setQuery(value)}
                  className="searchInput"
                />
              </div>
            </div>
            <div className="col-lg-4 offset-lg-1 col-sm-6">
              <div className="select-wrapper">
                <select
                  id="department"
                  value={filterDepartment}
                  onChange={e => setFilterDepartment(e.target.value)}
                >
                  <option value="none">DEILD</option>
                  <option value="soludeild">RASKA Søludeild</option>
                  <option value="framleiosla">RASKA Framleiðsla</option>
                  <option value="handilin">BÚRØKT Handilin</option>
                  <option value="verkstaour">BÚRØKT Verkstaður</option>
                  <option value="fyrisiting">MBM Fyrisiting</option>
                  <option value="nevnd">MBM Nevnd</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-content">
        {filterDepartment === "soludeild" || filterDepartment === "none" ? (
          <div className="container-fluid">
            <div id="soludeild">
              <h4 className="contact-content__header">
                <span>RASKA Søludeild</span>
              </h4>
              <div className="row">
                {data.contactFields.acfContact.soludeild
                  .filter(
                    ({ soludeildPersonName }) =>
                      soludeildPersonName.includes(query) ||
                      soludeildPersonName.toLowerCase().includes(query)
                  )
                  .map((item, index) => (
                    <div
                      className="col-md-3 col-sm-4 col-6 col-count"
                      key={index}
                    >
                      <div className="contact-content__tile">
                        <div className="contact-content__tile-image">
                          {item.soludeildPersonImage && (
                            <img
                              src={item.soludeildPersonImage.sourceUrl}
                              alt=""
                            />
                          )}
                        </div>
                        {item.soludeildPersonName && (
                          <p>{item.soludeildPersonName}</p>
                        )}
                        {item.soludeildPersonPosition && (
                          <span>{item.soludeildPersonPosition}</span>
                        )}
                        {item.soludeildPersonPhone && (
                          <a href={`tel:${item.soludeildPersonPhone}`}>
                            {item.soludeildPersonPhone}
                          </a>
                        )}
                        {item.soludeildPersonEmail && (
                          <a href={`mailto:${item.soludeildPersonEmail}`}>
                            {item.soludeildPersonEmail}
                          </a>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {filterDepartment === "framleiosla" || filterDepartment === "none" ? (
          <div className="container-fluid">
            <div id="framleiosla">
              <h4 className="contact-content__header">
                <span>RASKA Framleiðsla</span>
              </h4>
              <div className="row">
                {data.contactFields.acfContact.framleiosla
                  .filter(
                    ({ framleioslaPersonName }) =>
                      framleioslaPersonName.includes(query) ||
                      framleioslaPersonName.toLowerCase().includes(query)
                  )
                  .map((item, index) => (
                    <div
                      className="col-md-3 col-sm-4 col-6 col-count"
                      key={index}
                    >
                      <div className="contact-content__tile">
                        <div className="contact-content__tile-image">
                          {item.framleioslaPersonImage && (
                            <img
                              src={item.framleioslaPersonImage.sourceUrl}
                              alt=""
                            />
                          )}
                        </div>
                        {item.framleioslaPersonName && (
                          <p>{item.framleioslaPersonName}</p>
                        )}
                        {item.framleioslaPersonPosition && (
                          <span>{item.framleioslaPersonPosition}</span>
                        )}
                        {item.framleioslaPersonPhone && (
                          <a href={`tel:${item.framleioslaPersonPhone}`}>
                            {item.framleioslaPersonPhone}
                          </a>
                        )}
                        {item.framleioslaPersonEmail && (
                          <a href={`mailto:${item.framleioslaPersonEmail}`}>
                            {item.framleioslaPersonEmail}
                          </a>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {filterDepartment === "handilin" || filterDepartment === "none" ? (
          <div className="container-fluid">
            <div id="handilin">
              <h4 className="contact-content__header">
                <span>BÚRØKT Handilin</span>
              </h4>
              <div className="row">
                {data.contactFields.acfContact.handilin
                  .filter(
                    ({ handilinPersonName }) =>
                      handilinPersonName.includes(query) ||
                      handilinPersonName.toLowerCase().includes(query)
                  )
                  .map((item, index) => (
                    <div
                      className="col-md-3 col-sm-4 col-6 col-count"
                      key={index}
                    >
                      <div className="contact-content__tile">
                        <div className="contact-content__tile-image">
                          {item.handilinPersonImage && (
                            <img
                              src={item.handilinPersonImage.sourceUrl}
                              alt=""
                            />
                          )}
                        </div>
                        {item.handilinPersonName && (
                          <p>{item.handilinPersonName}</p>
                        )}
                        {item.handilinPersonPosition && (
                          <span>{item.handilinPersonPosition}</span>
                        )}
                        {item.handilinPersonPhone && (
                          <a href={`tel:${item.handilinPersonPhone}`}>
                            {item.handilinPersonPhone}
                          </a>
                        )}
                        {item.handilinPersonEmail && (
                          <a href={`mailto:${item.handilinPersonEmail}`}>
                            {item.handilinPersonEmail}
                          </a>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {filterDepartment === "verkstaour" || filterDepartment === "none" ? (
          <div className="container-fluid">
            <div id="verkstaour">
              <h4 className="contact-content__header">
                <span>BÚRØKT Verkstaður</span>
              </h4>
              <div className="row">
                {data.contactFields.acfContact.verkstaour
                  .filter(
                    ({ verkstaourPersonName }) =>
                      verkstaourPersonName.includes(query) ||
                      verkstaourPersonName.toLowerCase().includes(query)
                  )
                  .map((item, index) => (
                    <div
                      className="col-md-3 col-sm-4 col-6 col-count"
                      key={index}
                    >
                      <div className="contact-content__tile">
                        <div className="contact-content__tile-image">
                          {item.verkstaourPersonImage && (
                            <img
                              src={item.verkstaourPersonImage.sourceUrl}
                              alt=""
                            />
                          )}
                        </div>
                        {item.verkstaourPersonName && (
                          <p>{item.verkstaourPersonName}</p>
                        )}
                        {item.verkstaourPersonPosition && (
                          <span>{item.verkstaourPersonPosition}</span>
                        )}
                        {item.verkstaourPersonPhone && (
                          <a href={`tel:${item.verkstaourPersonPhone}`}>
                            {item.verkstaourPersonPhone}
                          </a>
                        )}
                        {item.verkstaourPersonEmail && (
                          <a href={`mailto:${item.verkstaourPersonEmail}`}>
                            {item.verkstaourPersonEmail}
                          </a>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {filterDepartment === "utbunadur" || filterDepartment === "none" ? (
          <div className="container-fluid">
            <div id="utbunadur">
              <h4 className="contact-content__header">
                <span>FJÓS</span>
              </h4>
              <div className="row">
                {data?.contactFields?.acfContact?.utbunadur
                  ?.filter(
                    ({ utbunadurPersonName }) =>
                      utbunadurPersonName?.includes(query) ||
                      utbunadurPersonName?.toLowerCase().includes(query)
                  )
                  .map((item, index) => (
                    <div
                      className="col-md-3 col-sm-4 col-6 col-count"
                      key={index}
                    >
                      <div className="contact-content__tile">
                        <div className="contact-content__tile-image">
                          {item.utbunadurPersonImage && (
                            <img
                              src={item.utbunadurPersonImage.sourceUrl}
                              alt=""
                            />
                          )}
                        </div>
                        {item.utbunadurPersonName && (
                          <p>{item.utbunadurPersonName}</p>
                        )}
                        {item.utbunadurPersonPosition && (
                          <span>{item.utbunadurPersonPosition}</span>
                        )}
                        {item.utbunadurPersonPhone && (
                          <a href={`tel:${item.utbunadurPersonPhone}`}>
                            {item.utbunadurPersonPhone}
                          </a>
                        )}
                        {item.utbunadurPersonEmail && (
                          <a href={`mailto:${item.utbunadurPersonEmail}`}>
                            {item.utbunadurPersonEmail}
                          </a>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {filterDepartment === "fyrisiting" || filterDepartment === "none" ? (
          <div className="container-fluid">
            <div id="fyrisiting">
              <h4 className="contact-content__header">
                <span>MBM Fyrisiting</span>
              </h4>
              <div className="row">
                {data.contactFields.acfContact.fyrisiting
                  .filter(
                    ({ fyrisitingPersonName }) =>
                      fyrisitingPersonName.includes(query) ||
                      fyrisitingPersonName.toLowerCase().includes(query)
                  )
                  .map((item, index) => (
                    <div
                      className="col-md-3 col-sm-4 col-6 col-count"
                      key={index}
                    >
                      <div className="contact-content__tile">
                        <div className="contact-content__tile-image">
                          {item.fyrisitingPersonImage && (
                            <img
                              src={item.fyrisitingPersonImage.sourceUrl}
                              alt=""
                            />
                          )}
                        </div>
                        {item.fyrisitingPersonName && (
                          <p>{item.fyrisitingPersonName}</p>
                        )}
                        {item.fyrisitingPersonPosition && (
                          <span>{item.fyrisitingPersonPosition}</span>
                        )}
                        {item.fyrisitingPersonPhone && (
                          <a href={`tel:${item.fyrisitingPersonPhone}`}>
                            {item.fyrisitingPersonPhone}
                          </a>
                        )}
                        {item.fyrisitingPersonEmail && (
                          <a href={`mailto:${item.fyrisitingPersonEmail}`}>
                            {item.fyrisitingPersonEmail}
                          </a>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {filterDepartment === "nevnd" || filterDepartment === "none" ? (
          <div className="container-fluid">
            <div id="nevnd">
              <h4 className="contact-content__header">
                <span>MBM Nevnd</span>
              </h4>
              <div className="row">
                {data.contactFields.acfContact.nevnd
                  .filter(
                    ({ nevndPersonName }) =>
                      nevndPersonName.includes(query) ||
                      nevndPersonName.toLowerCase().includes(query)
                  )
                  .map((item, index) => (
                    <div
                      className="col-md-3 col-sm-4 col-6 col-count"
                      key={index}
                    >
                      <div className="contact-content__tile">
                        <div className="contact-content__tile-image">
                          {item.nevndPersonImage && (
                            <img src={item.nevndPersonImage.sourceUrl} alt="" />
                          )}
                        </div>
                        {item.nevndPersonName && <p>{item.nevndPersonName}</p>}
                        {item.nevndPersonPosition && (
                          <span>{item.nevndPersonPosition}</span>
                        )}
                        {item.nevndPersonPhone && (
                          <a href={`tel:${item.nevndPersonPhone}`}>
                            {item.nevndPersonPhone}
                          </a>
                        )}
                        {item.nevndPersonEmail && (
                          <a href={`mailto:${item.nevndPersonEmail}`}>
                            {item.nevndPersonEmail}
                          </a>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </section>
    </Layout>
  )
}

export const query = graphql`
  query {
    contactFields: wpPage(id: { eq: "cG9zdDoxMA==" }) {
      acfContact {
        contactContentColumns {
          contactContentHeader
          contactContentDescription
        }
        framleiosla {
          framleioslaPersonImage {
            sourceUrl
          }
          framleioslaPersonName
          framleioslaPersonPosition
          framleioslaPersonPhone
          framleioslaPersonEmail
        }
        soludeild {
          soludeildPersonImage {
            sourceUrl
          }
          soludeildPersonName
          soludeildPersonPosition
          soludeildPersonPhone
          soludeildPersonEmail
        }
        handilin {
          handilinPersonImage {
            sourceUrl
          }
          handilinPersonName
          handilinPersonPosition
          handilinPersonPhone
          handilinPersonEmail
        }
        verkstaour {
          verkstaourPersonImage {
            sourceUrl
          }
          verkstaourPersonName
          verkstaourPersonPosition
          verkstaourPersonPhone
          verkstaourPersonEmail
        }
        fyrisiting {
          fyrisitingPersonImage {
            sourceUrl
          }
          fyrisitingPersonName
          fyrisitingPersonPosition
          fyrisitingPersonPhone
          fyrisitingPersonEmail
        }
        utbunadur {
          utbunadurPersonImage {
            sourceUrl
          }
          utbunadurPersonName
          utbunadurPersonPosition
          utbunadurPersonPhone
          utbunadurPersonEmail
        }
        nevnd {
          nevndPersonImage {
            sourceUrl
          }
          nevndPersonName
          nevndPersonPosition
          nevndPersonPhone
          nevndPersonEmail
        }
      }
    }
  }
`

export default Contact
